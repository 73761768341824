import { inject, Signal } from '@angular/core';
import { isDefined, Store } from '@softline/core';
import { ModalStore, SOFTLINE_FEATURE_MODAL } from '@softline/ui-core';

export async function askUnsaved(component: { dirty: Signal<boolean> }): Promise<boolean> {
  if(!isDefined(component.dirty)) {
    console.warn(`[askUnsaved()] The page '${component.constructor?.name}' should have a dirty property to ensure the user not leaving the page without saving the data.`);
    return true;
  }
  if(component.dirty()) {
    const store = inject(Store);
    const result = await store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.ask, {
      title: '#SOFTAPPS_CORE.GUARDS.UNSAVED_CHANGES.TITLE',
      question: '#SOFTAPPS_CORE.GUARDS.UNSAVED_CHANGES.MESSAGE',
      dismiss: true
    });
    return result === 'YES';
  }
  return true;
}
