export const environment = {
  production: true,
  connection: {
    name: 'Schubert',
    host: 'https://artikelinfo.schuberth.at',
    port: 443,
    basePath: '/restapi/api'
  },
  androidUpdateRepository: ''
};
