import { InjectionToken } from '@angular/core';
import { Store2Feature } from './store2-feature';
import { BehaviorSubject } from 'rxjs';
import { Dictionary } from '../types/dictionary';
import { CollectionRepository, ReadonlyListRepository, Repository } from '../data2/abstraction';


export type LoadingState = null | 'pending' | 'loading' | 'loaded' | 'canceled' | 'failed';
export type SavingState = null | 'pending' | 'saving' | 'saved' | 'canceled' | 'failed';

export const SOFTLINE_STORE_STATE = new InjectionToken<BehaviorSubject<Dictionary<any>>>('SOFTLINE_STORE_STATE',
  { providedIn: 'root', factory: () => new BehaviorSubject<Dictionary<any>>({})
  });

export const SOFTLINE_FEATURE_NAME = new InjectionToken<string>('SOFTLINE_FEATURE_NAME');
export const SOFTLINE_FEATURE_INITIAL_STATE = new InjectionToken<any>('SOFTLINE_FEATURE_INITIAL_STATE');
export const SOFTLINE_FEATURE_ID_FUNC = new InjectionToken<(item: object) => string | number>('SOFTLINE_FEATURE_ID_FUNC');

export const SOFTLINE_REPOSITORY = new InjectionToken<Repository<any, any>>('SOFTLINE_REPOSITORY');
export const SOFTLINE_COLLECTION_REPOSITORY = new InjectionToken<CollectionRepository<any, any, any>>('SOFTLINE_COLLECTION_REPOSITORY');
export const SOFTLINE_LIST_REPOSITORY = new InjectionToken<ReadonlyListRepository<any>>('SOFTLINE_LIST_REPOSITORY');

export const SOFTLINE_STORE_FEATURES = new InjectionToken<Store2Feature<any>[]>('SOFTLINE_STORE_FEATURES');
export const SOFTLINE_FEATURE_COMMIT_LOAD_DATA_STRATEGY = new InjectionToken('SOFTLINE_FEATURE_COMMIT_LOAD_DATA_STRATEGY');
