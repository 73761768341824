import { Component, computed, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartData } from '../data/chart-data';

interface Segment {
  d: string;
  startPoint: [number, number];
  endPoint: [number, number];
  color: string;
}

@Component({
  selector: 'soft-gauge-chart',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './gauge-chart.component.html',
  styleUrl: './gauge-chart.component.scss',
})
export class GaugeChartComponent {
  radius = signal<number>(100);
  strokeWidth = signal<number>(16);

  offsetX = computed(() => this.radius() + this.strokeWidth());
  offsetY = computed(() => this.radius() + this.strokeWidth());

  data = input<ChartData[]>([]);
  total = computed(() => this.data().reduce((acc, curr) => acc + curr.value, 0));

  segmentHovered = output<string>();

  path = computed(() => {
    const data = this.data();
    const total = this.total();
    const radius = this.radius();
    const offsetX = this.offsetX();
    const offsetY = this.offsetY();

    const path: Segment[] = [];
    let startPoint: [number, number] = [offsetX - radius, offsetY];
    let angle = 0;
    for (const value of data) {
      angle = angle + ((value.value / total) * Math.PI);
      let endPoint: [number, number] = [
        isNaN(offsetX - Math.cos(angle) * radius) ? 0 : offsetX - Math.cos(angle) * radius,
        isNaN(offsetY - Math.sin(angle) * radius) ? 0 : offsetY - Math.sin(angle) * radius
      ];
      const d = `M ${startPoint[0]} ${startPoint[1]} A ${radius} ${radius} 0 0 1 ${endPoint[0]} ${endPoint[1]}`;
      path.push({startPoint, endPoint, color: value.color, d});
      startPoint = endPoint;
    }
    let returnPath = path.filter(segment =>
      segment.startPoint[0] !== 0 && segment.startPoint[1] !== 0 && segment.endPoint[0] !== 0 && segment.endPoint[1] !== 0
    );
    if (returnPath.length <= 1) {
      returnPath = [
        {
          d: 'M 16 116 A 100 100 0 0 1 216 115.99999999999999',
          startPoint: [offsetX - radius, offsetY],
          endPoint: [216, 115.99999999999999],
          color: '#dcd7d7'
        }
      ];
    }
    return returnPath;
  })
}
