import {
  computed,
  inject,
  Injectable,
  InjectionToken, Injector,
  OnDestroy,
  OnInit,
  runInInjectionContext,
  signal,
  Signal
} from '@angular/core';
import {
  Command2,
  Command2Service,
  handleRequestErrors,
  MenuItem, MenuItemStore2,
  WithCommands2,
  WithMenuItems
} from '@softline/application';
import {
  CancelledError,
  Dictionary,
  NestedStore2Feature,
  RepositoryCollectionStore2,
  SOFTLINE_SERVICE_UUID,
  Store
} from '@softline/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { distinct, of } from 'rxjs';

type Constructor<T extends {}> = new(...args: any[]) => T;

type CreatePageMixinParams<T extends object, TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>>
  = {store: InjectionToken<TStore>, repositoryFeature: (o: TStore) => RepositoryCollectionStore2<T>};

export const WithCreatePage = <T extends object, TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>, TBase extends Constructor<{}> = Constructor<{}>>(params: CreatePageMixinParams<T, TStore>, Base: TBase = (class {} as any)) => {
  @Injectable()
  abstract class CreatePageMixin
    extends Base
    implements OnInit, OnDestroy {

    #SAVE_MENU_NAME = this.constructor.name+'SaveMenuItems';
    #SAVE_COMMAND_NAME = this.constructor.name+'SaveCommand';
    #token: string | null = null;
    #destroying = false;
    #registeredCommands: Command2[] = [];

    #injector = inject(Injector);
    #uuid = inject(SOFTLINE_SERVICE_UUID);
    #store = inject(Store);
    #commandService = inject(Command2Service);
    #menuItemStore = inject(MenuItemStore2);

    #creating = signal(false);

    #repositoryStore: RepositoryCollectionStore2<T>;

    abstract formValue: Signal<Partial<T> | null>;

    dirty = computed(() => {
      return this.formValue() !== null;
    });

    pathParams = signal<Dictionary<unknown> | undefined>(undefined);
    createPageMenuItems : Signal<MenuItem[]> = computed(() => {
        const creating = this.#creating();
        return [{
          type: 'command',
          name: this.#SAVE_COMMAND_NAME,
          outlet: 'responsive',
          class: 'soft-button accent',
          icon: creating ? 'fa-regular fa-spinner fa-spin' : 'fa-regular fa-floppy-disk',
          title: '#SOFTAPPS_CORE.MENU_ITEMS.SAVE',
        }];
      }
    );

    createPageCommands: Signal<Command2[]> = computed(() => {
      const creating= this.#creating()
      return [{
        name: this.#SAVE_COMMAND_NAME,
        canExecute: !creating,
        execute: async () => {
          await this.submit();
        }
      }]
    });

    constructor(...args: any[]) {
      super(...args);
      const injectedStore = inject(params.store);
      this.#repositoryStore = params.repositoryFeature(injectedStore);
    }

    ngOnInit(): void {
      if(super['ngOnInit'])
        super['ngOnInit']();
      runInInjectionContext(this.#injector, () => {
        toObservable(this.createPageCommands)
          .pipe(
            distinct(),
            takeUntilDestroyed(),
          )
          .subscribe(o => {
            const registeredCommands = this.#registeredCommands;
            this.#registeredCommands = o;
            for(const command of registeredCommands)
              this.#commandService.remove(command.name);
            for(const command of o) {
              this.#commandService.register(command);
            }
          });
        toObservable(this.createPageMenuItems)
          .pipe(
            distinct(),
            takeUntilDestroyed(),
          )
          .subscribe(o => {
            this.#menuItemStore.set(this.#SAVE_MENU_NAME, o ?? []);
          })
      });
    }

    ngOnDestroy(): void {
      this.#menuItemStore.remove(this.#SAVE_MENU_NAME);
      for(const command of this.#registeredCommands)
        this.#commandService.remove(command.name);

      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

    async submit(): Promise<void> {
      await this.create(this.formValue());
    }

    async create(value: Partial<T> | null): Promise<T | null> {
      if(!value)
        return null;
      this.#creating.set(true);
      try {
        if(this.#token)
          await this.#repositoryStore.cancel(this.#token);
        this.#token = this.#uuid();
        const params = this.pathParams();
        return await this.#repositoryStore.create({pathParams: params, value, token: this.#token });
      }
      catch (e) {
        if(!this.#destroying && !(e instanceof CancelledError))
          handleRequestErrors(this.#store, e);
        return null;
      }
      finally {
        this.#token = null;
        this.#creating.set(false);
      }
    }
  }
  return CreatePageMixin;
}
