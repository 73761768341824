import { ChartData } from '../../data/chart-data';

export function calculateSegments<T>(data: ChartData[], total: number) {
  const offset = 25;
  const result: T[] = [];

  let percentages: number[] = [];

  data.forEach((data, index) => {
    const percentage = data.value / total * 100;
    const dasharray = `${percentage} ${100 - percentage}`;

    const percentageSum = percentages.reduce((acc, curr) => acc + curr, 0);
    const dasharrayOffset = index === 0 ? offset : (100 - percentageSum) + offset;

    percentages.push(percentage);

    result.push({
      strokeDasharray: dasharray,
      strokeDasharrayOffset: dasharrayOffset,
      color: data.color ?? 'black'
    } as T);
  });

  return result;
}
