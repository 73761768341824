import {
  computed,
  inject,
  Injectable,
  Injector,
  OnDestroy,
  OnInit,
  runInInjectionContext,
  Signal
} from '@angular/core';
import {
  Command2, Command2Service,
  MenuItem, MenuItemStore2,
  ScannerStore2,
  WithCommands2,
  WithMenuItems,
  WithScan,
  WithScanParams
} from '@softline/application';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { distinct } from 'rxjs';

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithScanner = <T extends Constructor<{}>>(params: WithScanParams, Base: T = (class {} as any)) => {
  @Injectable()
  abstract class ScannerMixin
    extends WithScan(params, Base)
    implements OnInit, OnDestroy {

    #SCAN_MENU_NAME = this.constructor.name+'ScanMenuItems';
    #SCAN_COMMAND_NAME = this.constructor.name+'ScanCommand';

    #registeredCommands: Command2[] = [];

    #injector = inject(Injector);
    #commandService = inject(Command2Service);
    #menuItemStore = inject(MenuItemStore2);
    #scannerStore = inject(ScannerStore2);


    scanMenuItems : Signal<MenuItem[]> = computed(() => {
        return [{
          type: 'command',
          name: this.#SCAN_COMMAND_NAME,
          outlet: 'responsive',
          class: 'soft-button',
          icon: 'fa-regular fa-barcode-scan',
          priority: 1,
        }];
      }
    );

    scanCommands: Signal<Command2[]> = computed(() => {
      const scanning = this.#scannerStore.scanning();
      return [{
        name: this.#SCAN_COMMAND_NAME,
        canExecute: !scanning,
        execute: async () => {
          await this.#scannerStore.startScan(params.labelType);
        }
      }]
    });

    constructor(...args: any[]) {
      super(...args);
    }

    override ngOnInit(): void {
      if(super['ngOnInit'])
        super['ngOnInit']();
      runInInjectionContext(this.#injector, () => {
        toObservable(this.scanCommands)
          .pipe(
            distinct(),
            takeUntilDestroyed(),
          )
          .subscribe(o => {
            const registeredCommands = this.#registeredCommands;
            this.#registeredCommands = o;
            for(const command of registeredCommands)
              this.#commandService.remove(command.name);
            for(const command of o) {
              this.#commandService.register(command);
            }
          });
        toObservable(this.scanMenuItems)
          .pipe(
            distinct(),
            takeUntilDestroyed(),
          )
          .subscribe(o => {
            this.#menuItemStore.set(this.#SCAN_MENU_NAME, o ?? []);
          })
      });
    }

    override ngOnDestroy(): void {
      this.#menuItemStore.remove(this.#SCAN_MENU_NAME);
      for(const command of this.#registeredCommands)
        this.#commandService.remove(command.name);

      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }
  }
  return ScannerMixin;
}
