<div class="soft-top-container">
  <div class="flex flex-row items-center flex-nowrap">
    @if (visibleCurrent(); as current) {
      <soft-default-header class="w-full"
                        [overline]="'#SOFTAPPS_CORE.PAGES.STEPPER.OVERLINE' | translate | interpolate:{current: visibleIndex() + 1, total: visibleSteps().length}"
                        [title]="current?.title() | translate"
                        [subtitle]="current?.description() | translate"></soft-default-header>
    }
    <soft-responsive-menu></soft-responsive-menu>
  </div>
  <soft-step-header class="-mx-4 mt-2"
                    [steps]="steps() | stepHeaderSteps"
                    [currentIndex]="index()"></soft-step-header>
  @if(headerTemplate()) {
    <ng-container *ngTemplateOutlet="headerTemplate()"></ng-container>
  }
</div>
@if (currentTemplate(); as template){
  <ng-container *ngTemplateOutlet="template"></ng-container>
}



