import {Routes} from "@angular/router";
import {AuthenticationGuard} from "@softline/auth";
import {loginRoutes} from "@softapps/allgemein/login";
import {connectionSettingsRoutes, settingsRoutes} from "@softapps/allgemein/utils";
import {passwordRoutes} from "@softapps/allgemein/password";
import {moduleRoutes} from "@softapps/allgemein/modules";
import {artikelInfoRoutes} from '@softapps/wws/artikel-info';
import { bestellvorschlagRoutes } from '@softapps/mde/bestellvorschlag';
import { bestellerfassungRoutes } from '@softapps/mde/bestellerfassung';
import { etikettendruckRoutes } from '@softapps/mde/etikettendruck';
import { lagerInventurRoutes } from '@softapps/mde/inventur';
import { retourenRoutes } from '@softapps/mde/retouren';
import { warenverprobungRoutes } from '@softapps/mde/warenverprobung';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('@softline/application').then((o) => o.ShellComponent),
    children: [
      // Allgemein
      ...moduleRoutes,
      ...settingsRoutes,

      // Libs
      ...artikelInfoRoutes,
      ...bestellvorschlagRoutes,
      ...bestellerfassungRoutes,
      ...etikettendruckRoutes,
      ...lagerInventurRoutes,
      ...retourenRoutes,
      ...warenverprobungRoutes,
    ],
    canActivate: [AuthenticationGuard],
  },
  {
    path: '',
    loadComponent: () => import('@softline/application').then((o) => o.PublicShellComponent),
    children: [
      ...loginRoutes,
      ...passwordRoutes,
      ...connectionSettingsRoutes,
      ...settingsRoutes
    ],
  },
];
