import {
  AfterContentInit,
  Component,
  computed,
  contentChildren,
  input,
  output,
  Signal,
  signal,
  TemplateRef
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  Command2,
  HeaderComponent,
  MenuItem,
  ResponsiveMenuComponent,
  StepHeaderComponent,
  WithBackNavigation,
  WithCommands2,
  WithMenuItems
} from '@softline/application';
import {UiCoreModule} from '@softline/ui-core';
import {WizardStepComponent} from './step/wizard-step.component';
import {WizardItemBase} from './wizard-item-base';
import {WizardActionDirective} from './action/wizard-action.directive';
import {StepHeaderStepsPipe} from './step-header-steps.pipe';

@Component({
  selector: 'soft-wizard',
  standalone: true,
  imports: [CommonModule, UiCoreModule, HeaderComponent, ResponsiveMenuComponent, StepHeaderComponent, StepHeaderStepsPipe],
  templateUrl: './wizard.component.html',
  styleUrl: './wizard.component.scss',
})
export class WizardComponent
  extends
    WithCommands2(
    WithMenuItems(
    WithBackNavigation(
    )))
  implements AfterContentInit
{

  running = signal(false);
  index = signal(0);

  headerTemplate = input<TemplateRef<any> | null>(null);

  done = output<void>();
  cancel = output<void>();

  steps = contentChildren(WizardItemBase);

  current: Signal<WizardItemBase | null> = computed(() => {
    const index = this.index();
    return this.steps()[index] ?? null;
  });

  visibleSteps = computed(() => {
    return this.steps().filter(step => step.state() !== 'hidden');
  });

  visibleIndex = computed(() => {
    let index = this.index();
    const steps = this.steps();
    while (index > -1 && steps[index].state() === 'hidden')
      index--;
    return index;
  });

  visibleCurrent = computed(() => {
    const index = this.visibleIndex();
    const steps = this.steps();
    return steps[index];
  });

  nextStep: Signal<WizardItemBase | null> = computed(() => {
    const index = this.index();
    const steps = this.steps();
    for (let i = index + 1; index < steps.length; i++)
      if(steps[i]?.state() !== 'disabled')
        return steps[i] ?? null
    return null;
  });

  currentTemplate = computed(() => {
    const step = this.visibleCurrent();
    if(step instanceof WizardStepComponent)
      return step.template();
    return null;
  });

  override menuItems: Signal<MenuItem[]> = computed(() => {
    const current = this.current();
    const next = this.nextStep();
    const running = this.running();
    return [{
      type: 'command',
      name: 'StepperPageNextCommand',
      outlet: 'responsive',
      class: 'soft-button accent text-nowrap',
      icon: running ? 'fa-regular fa-spinner fa-spin' : next?.icon() ?? 'fa-regular fa-arrow-right',
      title: running ? current?.title() : next?.title(),
    }]
  });

  override commands: Signal<Command2[]> = computed(() => {
    const current = this.current();
    const next = this.nextStep();
    const running = this.running();
    return [{
      name: 'StepperPageNextCommand',
      canExecute: !running && current?.canLeave() && next?.canEnter() && next?.state() !== 'disabled',
      execute: async () => await this.next()
    }]
  });

  constructor() {
    super();
  }

  ngAfterContentInit() {
    const current = this.current();
    if(current)
      current.enter.emit(current);
  }

  override async navigateBack(): Promise<void> {
    const index = this.index();
    if(index > 0)
      this.index.set(index - 1);
    else
      this.cancel.emit();
  }

  private async runAction(action: WizardActionDirective): Promise<void> {
    try {
      this.running.set(true);
      const proceed = await new Promise<boolean>((resolve, reject) => {
        const callback = (proceed: boolean) => {
          resolve(proceed);
        };

        action.execute.emit(callback)
      });

      let index: number;
      const nextStep = this.nextStep();
      if(proceed && nextStep)
        index = this.steps().indexOf(nextStep);
      else {
        index = this.index();
        const steps = this.steps();
        for(let i = index - 1; index >= 0; i--)
          if (steps[i].state() !== 'disabled')
            break;
      }
      if(index > -1)
        this.index.set(index);
    }
    catch (e) {
      console.error(e);
    }
    finally {
      this.running.set(false);
    }
  }

  public async next(): Promise<void> {
    const current = this.current();
    const next = this.nextStep();

    if (next) {
      const nextIndex = this.steps().indexOf(next);
      this.index.set(nextIndex);
      current?.next.emit({ current, next });
      current?.leave.emit(current);
      next.enter.emit(next);

      if (next instanceof WizardActionDirective)
        await this.runAction(next);
    } else {
      this.done.emit();
    }
  }
}
