import {
  computed,
  effect,
  inject,
  Injectable,
  InjectionToken,
  Injector,
  OutputRefSubscription,
  runInInjectionContext,
  signal,
  Signal
} from '@angular/core';
import { CollectionStore2, Id, NestedStore2Feature, RepositoryCollectionStore2, Store } from '@softline/core';
import { DynamicFormComponent, SOFTLINE_FEATURE_DEFINITIONS, WithDefinition } from '@softline/dynamic';
import { distinct } from 'rxjs';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { WithEditPage } from './edit-page.mixin';
import { DefinitionStore } from '@softline/dynamic';

type Constructor<T extends {}> = new(...args: any[]) => T;

type DynamicEditPageMixinParams<T extends object, TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>>
  = {store: InjectionToken<TStore>, repositoryFeature: (o: TStore) => RepositoryCollectionStore2<T>, collectionFeature: (o: TStore) => CollectionStore2<T>, definitionName: string, headerDefinitionName?: string};

export const WithDynamicEditPage = <T extends object, TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>, TBase extends Constructor<{}> = Constructor<{}>>(params: DynamicEditPageMixinParams<T, TStore>, Base: TBase = (class {} as any)) => {
  @Injectable()
  abstract class DynamicEditPageMixin
    extends
      WithEditPage(params,
      WithDefinition(params.definitionName, Base
      ))
  {
    #valueSubscription?: OutputRefSubscription;
    #submitSubscription?: OutputRefSubscription;

    #injector = inject(Injector);
    #store = inject(Store);

    headerDefinition = this.#store.signal(
      SOFTLINE_FEATURE_DEFINITIONS,
      DefinitionStore.getters.definition,
      params.headerDefinitionName
    )

    abstract form: Signal<DynamicFormComponent<T> | undefined>;

    override dirty = computed(() => {
      return this.form()?.dirty() ?? false;
    })

    override formValue = signal<Partial<T> | null>(null);

    constructor(...args: any[]) {
      super(...args);
    }

    override async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();
      if(params.headerDefinitionName) {
        const definition = this.#store.dispatch(
          SOFTLINE_FEATURE_DEFINITIONS,
          DefinitionStore.actions.loadOnce,
          { name: params.headerDefinitionName }
        );
      }

      runInInjectionContext(this.#injector, () => {
        toObservable(this.form)
          .pipe(
            distinct(),
            takeUntilDestroyed(),
          )
          .subscribe(form => {
            if(this.#valueSubscription)
              this.#valueSubscription.unsubscribe();
            if(this.#submitSubscription)
              this.#submitSubscription.unsubscribe();

            if(!form)
              return;

            this.#valueSubscription = form.valueChange.subscribe(value => {
              this.formValue.set(value);
            });
            this.#submitSubscription = form.formSubmit.subscribe(async value => {
              if(!this.form()?.valid())
                return;
              await this.save(this.formValue());
            });
          })
      });
    }

    override ngOnDestroy(): void {
      if(this.#valueSubscription)
        this.#valueSubscription.unsubscribe();
      if(this.#submitSubscription)
        this.#submitSubscription.unsubscribe();

      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

    override async submit(): Promise<void> {
      const form = this.form();
      if(form)
        form.submit();
    }
  }
  return DynamicEditPageMixin;
}
