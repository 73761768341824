import {ApplicationConfig, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {routes} from './app.routes';
import {ConnectionModule, CoreModule, StoreModule} from '@softline/core';
import {UiCoreModule} from '@softline/ui-core';
import {environment} from '../environments/environment';
import {
  connectionSettingsProviders,
  contextStorageProviders,
  settingsProviders,
  userContextProviders,
  utilitiesProviders,
  vkforgContextProviders,
} from '@softapps/allgemein/utils';
import {
  AuthenticationModule,
  AuthorizationModule,
  JwtAuthenticationModule,
} from '@softline/auth';
import {
  AppearanceModule,
  ApplicationModule,
  ConsoleModule,
  DeveloperModule,
  ItemScanModule,
  MasterDataModule,
  OfflineModule,
  remoteConfigProviders,
} from '@softline/application';
import { DynamicModule } from '@softline/dynamic';
import { pdfViewerProviders } from '@softapps/allgemein/pdf-viewer';
import { loginProviders } from '@softapps/allgemein/login';
import { passwordProviders } from '@softapps/allgemein/password';
import { moduleProviders } from '@softapps/allgemein/modules';
import { artikelInfoProviders } from '@softapps/wws/artikel-info';
import { platform } from '../platforms/platform';
import { wwsCoreProviders } from "@softapps/wws/core";
import { bestellvorschlagProviders } from '@softapps/mde/bestellvorschlag';
import { bestellerfassungProviders } from '@softapps/mde/bestellerfassung';
import { etikettendruckProviders } from '@softapps/mde/etikettendruck';
import { inventurProviders } from '@softapps/mde/inventur';
import { retourenProviders } from '@softapps/mde/retouren';
import { warenverprobungProviders } from '@softapps/mde/warenverprobung';
import {SoftappsCoreModule} from '@softapps/allgemein/core';
export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserAnimationsModule,
      RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),

      // Framework
      CoreModule.forRoot(),
      StoreModule.forRoot(),
      UiCoreModule.forRoot(),
      ConnectionModule.forRoot(environment.connection),
      AuthenticationModule.forRoot(),

      JwtAuthenticationModule.forRoot(),
      AuthorizationModule.forRoot(),
      ApplicationModule.forRoot(),
      ItemScanModule.forRoot(),
      AppearanceModule.forRoot(),
      DynamicModule.forRoot(),
      MasterDataModule.forRoot(),
      DeveloperModule.forRoot(),
      ConsoleModule.forRoot(),
      OfflineModule.forRoot(),
      ItemScanModule.forRoot(),
      SoftappsCoreModule.forRoot(),
    ),

    // Allgemein
    ...utilitiesProviders,
    ...userContextProviders,
    ...settingsProviders,
    ...connectionSettingsProviders,
    ...pdfViewerProviders,
    ...loginProviders,
    ...passwordProviders,
    ...moduleProviders,
    ...remoteConfigProviders,

    ...vkforgContextProviders,
    ...contextStorageProviders,

    //WWS
    ...wwsCoreProviders,

    // Libs
    ...artikelInfoProviders,
    ...bestellvorschlagProviders,
    ...bestellerfassungProviders,
    ...etikettendruckProviders,
    ...inventurProviders,
    ...retourenProviders,
    ...warenverprobungProviders,

    ...platform.providers,
    { provide: LOCALE_ID, useValue: 'de' },
  ],
};
