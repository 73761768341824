import { Directive, forwardRef, input, output } from '@angular/core';
import { StepState, WizardItemBase } from '../wizard-item-base';

@Directive({
  selector: 'soft-wizard-action',
  standalone: true,
  providers: [
    {
      provide: WizardItemBase,
      useExisting: forwardRef(() => WizardActionDirective),
    },
  ]
})
export class WizardActionDirective extends WizardItemBase {
  override title = input.required<string>();
  override description = input<string>();
  override icon = input<string>();

  override state = input<StepState>('default');
  override canEnter = input(true);
  override canLeave = input(true);

  override enter = output<this>();
  override leave = output<this>();
  override next = output<{ current: WizardItemBase, next: WizardItemBase }>();

  execute = output<(proceed: boolean) => void>();
  constructor() { super(); }
}
