import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { calculateSegments } from './utils/calculate-segments.function';
import { ChartData } from '../data/chart-data';

interface Segment {
  strokeDasharray: string;
  strokeDasharrayOffset: number;
  color: string;
}

@Component({
  selector: 'soft-circular-gauge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './circular-gauge.component.html',
  styleUrl: './circular-gauge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CircularGaugeComponent {
  data = input.required<ChartData[]>();
  segmentHovered = output<string>();

  total = computed(() =>
    this.data().reduce((acc, curr) => acc + curr.value, 0)
  );

  readonly segments = computed<Segment[]>(() => {
    const total = this.total();
    const data = this.data();

    return calculateSegments(data, total);
  });
}
