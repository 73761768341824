import { Pipe, PipeTransform } from '@angular/core';
import { WizardItemBase, WizardStepComponent } from '@softapps/allgemein/core';
import { Step } from '@softline/application';

@Pipe({
  name: 'stepHeaderSteps',
  standalone: true,
})
export class StepHeaderStepsPipe implements PipeTransform {
  transform(value: readonly WizardItemBase[]): Step[] {
    return value
      .map(o => ({header: o.title(), subheader: o.description(), icon: o.icon(), state: o.state()}));
  }
}
