import {
  FieldOkConfig,
  FieldOkListViewComponent,
  FieldOkMultiselectListViewComponent,
  QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { AbteilungListView } from "./list-view/abteilung.list-view";

export const abteilungFieldOk: FieldOkConfig = {
  name: 'abteilung',
  type: 'default',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: AbteilungListView,
      sort: {
        properties: [
          {property: 'objekt', title: 'Objekt'},
        ]
      }
    }
  }
  ]
};
export const abteilungMultiselectFieldOk: FieldOkConfig = {
  name: 'abteilung',
  type: 'multi',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl',
    config: {
      component: AbteilungListView,
      sort: {
        properties: [
          {property: 'objekt', title: 'Objekt'},
        ]
      }
    }
  }
  ]
};

export const abteilungQueryFieldOk: FieldOkConfig = {
  name: 'abteilung',
  type: 'query',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: AbteilungListView,
      sort: {
        properties: [
          {property: 'objekt', title: 'Objekt'},
        ]
      }
    }
  }
  ]
};
